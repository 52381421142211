import { DEFAULT_STATUS_KEY, StatusInfo } from 'src/core/StatusInfo';

export const ApplicationStatusInfoMap: Record<string, StatusInfo> = {
    ON_KYC: {
        color: 'var(--kyc-color)',
        borderColor: 'var(--kyc-border-color)',
        title: 'On KYC Verification',
        backgroundColor: 'var(--kyc-background)',
    },
    VERIFICATION: {
        color: 'var(--verification-color)',
        borderColor: 'var(--verification-border-color)',
        title: 'Verification',
        backgroundColor: 'var(--verification-background)',
    },
    UPDATE_REQUESTED: {
        color: 'var(--update-requested-color)',
        borderColor: 'var(--update-requested-border-color)',
        title: 'Update requested',
        backgroundColor: 'var(--update-requested-background)',
    },
    UNDERWRITING: {
        color: 'var(--underwriting-color)',
        borderColor: 'var(--underwriting-border-color)',
        title: 'Underwriting',
        backgroundColor: 'var(--underwriting-background)',
    },
    DECLINED: {
        color: 'var(--declined-color)',
        borderColor: 'var(--declined-border-color)',
        title: 'Declined',
        backgroundColor: 'var(--declined-background)',
    },
    CANCELED: {
        color: 'var(--canceled-color)',
        borderColor: 'var(--canceled-border-color)',
        title: 'Canceled',
        backgroundColor: 'var(--canceled-background)',
    },
    APPROVED: {
        color: 'var(--approved-color)',
        borderColor: 'var(--approved-border-color)',
        title: 'Approved',
        backgroundColor: 'var(--approved-background)',
    },
    DOWN_PAYMENT_COLLECTED: {
        color: 'var(--disbursed-color)',
        borderColor: 'var(--disbursed-border-color)',
        title: 'Down payment collected',
        backgroundColor: 'var(--disbursed-background)',
    },
    ERROR: {
        color: 'var(--error-color)',
        borderColor: 'var(--error-border-color)',
        title: 'Error',
        backgroundColor: 'var(--error-background)',
    },
    [DEFAULT_STATUS_KEY]: {
        color: '#000000',
        borderColor: '#C5C5C5',
        title: '',
        backgroundColor: '#C5C5C5',
    },
};
